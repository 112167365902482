import React from 'react';
import PropTypes from 'prop-types';

import { VideoWrapper, Iframe } from './style';

const Video = ({ href, title }) => (
  <VideoWrapper>
    <Iframe
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      mozallowfullscreen="true"
      src={href}
      title={title}
      webkitallowfullscreen="true"
    />
  </VideoWrapper>
);

Video.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Video.defaultProps = {
  title: 'video',
};

export default Video;
