import classNames from 'classnames/bind';
import React from 'react';
import PropType from 'prop-types';

import Layout from 'components/Layout';
import Tuto1 from 'assets/images/landing-tne/tuto1.png';
import Tuto2 from 'assets/images/landing-tne/tuto2.jpg';
import Tuto3 from 'assets/images/landing-tne/tuto3.png';
import UniversiteParisCite from 'assets/images/index/universite-paris-cite.png';
import RegionNormandie from 'assets/images/index/region-normandie.png';
import EducationNationale from 'assets/images/landing-tne/education-nationale.png';
import Udl from 'assets/images/index/udl.png';
import SteveMasson from 'assets/images/index/steve-masson.png';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import Testimony from 'components/Shared/Testimony';
import Video from 'wooclap/components/video';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const IndexPage = ({ pageContext: { lang, layout, content, urls } }) => (
  <Layout lang={lang} t={layout} urls={urls}>
    <main className={cx('main')}>
      <div className={cx('bubbles')}>
        <h1 className={cx('page-title')}>{content.pageTitle}</h1>
        {/* Bloc 1 */}
        <section className={cx('student-progress')}>
          <div className={cx('text-bloc', 'student-progress-bloc')}>
            <span className={cx('student-progress-title')}>
              {content.bloc1Title.toUpperCase()}
            </span>
            <p className={cx('student-progress-paragraph')}>
              {content.bloc1Text}
              <ul>
                <li>{content.bloc1Text2}</li>
                <li>{content.bloc1Text3}</li>
              </ul>
              {content.bloc1Text4}
              <ul>
                <li>{content.bloc1Text5}</li>
                <li>{content.bloc1Text6}</li>
                <li>{content.bloc1Text7}</li>
              </ul>
            </p>

            <div className={cx('actions')}>
              <Button
                as="a"
                className={cx('cta-button')}
                href={content.Bloc1CTA.link}
                intent="primary"
                size="large"
              >
                {content.Bloc1CTA.label}
              </Button>
            </div>
          </div>
          <div className={cx('video-wrapper')}>
            <Video href={content.bloc1Video} />
          </div>
        </section>
        {/* Bloc 2 */}
        <section>
          <h2 className={cx('cards-title')}>{content.bloc2Title}</h2>
          <p className={cx('cards-subtitle')}>{content.bloc2Subtitle}</p>
          <div className={cx('cards')}>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="alarm-clock" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part1Title}</h3>
              <p className={cx('paragraph-card')}>
                <strong>{content.bloc2Part1TextStrong}</strong>
                {content.bloc2Part1Text}
              </p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="repeat" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part2Title}</h3>
              <p className={cx('paragraph-card')}>
                <strong>{content.bloc2Part2TextStrong}</strong>
                {content.bloc2Part2Text}
              </p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="brain" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part3Title}</h3>
              <p className={cx('paragraph-card')}>
                <strong>{content.bloc2Part3TextStrong}</strong>
                {content.bloc2Part3Text}
              </p>
            </div>
          </div>
          <div className={cx('centered-cta')}>
            <Button
              as="a"
              className={cx('cta-button')}
              href={content.bloc2CTA.link}
              intent="primary"
              size="large"
            >
              {content.bloc2CTA.label}
            </Button>
          </div>
        </section>
        {/* Bloc 3 */}
        <section className={cx('testimony')}>
          <p className={cx('main-testimony')}>{content.testimony1.content}</p>
          <div className={cx('testimony-author')}>
            <img alt="" className={cx('author-picture')} src={SteveMasson} />
            <div className={cx('author-content')}>
              <div className={cx('author-header')}>
                <p className={cx('author-name')}>{content.testimony1.author}</p>
                <p className={cx('author-level')}>{content.testimony1.level}</p>
              </div>
              <p className={cx('author-biography')}>
                {content.testimony1.biography}
              </p>
            </div>
          </div>
          <div className={cx('secondary-testimonies')}>
            <Testimony content={content.testimony2} />
            <Testimony content={content.testimony3} />
          </div>
        </section>
        {/* Bloc 4 */}
        <section>
          <div className={cx('long-cards')}>
            <div className={cx('long-card')}>
              <div className={cx('card-symbol')}>
                <Icon className={cx('icon')} name="laugh-wink" />
              </div>
              <h3 className={cx('card-headline')}>{content.bloc4Part1Title}</h3>
              <p className={cx('card-paragraph')}>{content.bloc4Part1Text1}</p>
              <p className={cx('card-paragraph')}>{content.bloc4Part1Text2}</p>
              <p className={cx('card-paragraph')}>{content.bloc4Part1Text3}</p>
              <div className={cx('image-wrapper')}>
                <img alt="tutoriel-1" className={cx('image')} src={Tuto1} />
              </div>
            </div>
            <div className={cx('long-card')}>
              <div className={cx('card-symbol')}>
                <Icon className={cx('icon')} name="brain" />
              </div>
              <h3 className={cx('card-headline')}>{content.bloc4Part2Title}</h3>
              <p className={cx('card-paragraph')}>{content.bloc4Part2Text1}</p>
              <p className={cx('card-paragraph')}>{content.bloc4Part2Text2}</p>
              <div className={cx('image-wrapper')}>
                <img
                  alt="tutoriel-2"
                  className={cx('image', 'space-top')}
                  src={Tuto2}
                />
              </div>
            </div>
            <div className={cx('long-card')}>
              <div className={cx('card-symbol')}>
                <Icon className={cx('icon')} name="rocket" />
              </div>
              <h3 className={cx('card-headline')}>{content.bloc4Part3Title}</h3>
              <p className={cx('card-paragraph')}>{content.bloc4Part3Text}</p>
              <div className={cx('image-wrapper')}>
                <img alt="tutoriel-3" className={cx('image')} src={Tuto3} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <section className={cx('community')}>
      <span className={cx('community-title')}>{content.bloc5Text}</span>
      <p>
        <Button
          as="a"
          className={cx('community-action')}
          href={content.bloc5CTA.link}
          intent="primary"
          size="large"
        >
          {content.bloc5CTA.label}
        </Button>
      </p>
    </section>
    <section className={cx('users')}>
      <span className={cx('users-title')}>{content.bloc6Title}</span>
      <p className={cx('users-content')}>{content.bloc6Text}</p>
    </section>
    {lang === 'fr' && (
      <section className={cx('partners')}>
        <div className={cx('partner-cards')}>
          {[UniversiteParisCite, EducationNationale, Udl, RegionNormandie].map(
            (image) => (
              <div className={cx('partner-card')}>
                <img alt="" className={cx('partner-card-image')} src={image} />
              </div>
            ),
          )}
        </div>
      </section>
    )}
  </Layout>
);

IndexPage.propTypes = {
  pageContext: PropType.object.isRequired,
};

export default IndexPage;
