import styled from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 62.5%; /* Ratio 16:9 */
  position: relative;
  margin-top: 30px;
  border-radius: 20px;
  overflow: hidden;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
